import React from "react";
import Trans from "../Translate";
// import { CheckCircle, XCircle, AlertTriangle } from "react-feather";
// import styles from './styles.module.scss';
// import alertSuccess from '../../../images/alert-success.svg';
// import alertError from '../../../images/alert-error.svg';
// import alertInfo from '../../../images/alert-info.svg';

const getErrors = (errors = {}, messageCode) => {
  if (errors === null) {
    return null;
  }
  if (errors.form) {
    errors = errors.form;
  }

  if (Object.entries(errors).length === 0 && errors.constructor === Object) {
    return null;
  }
  if (Object.entries(errors).length === 0) {
    return (
      <Trans i18nKey={messageCode}>
        There was a problem with your submission. Please try again.
      </Trans>
    );
  }
  if (Array.isArray(errors.graphQLErrors)) {
    if (errors.graphQLErrors.length > 0) {
      return errors.graphQLErrors[0].message;
    }
    return (
      <Trans i18nKey={messageCode}>
        There was a problem with your submission. Please try again.
      </Trans>
    );
  }
  if (Array.isArray(errors)) {
    // return errors[0].message;
    return errors.map((error) => (
      <>
        <Trans i18nKey={messageCode}>{error}</Trans>
        <br />
      </>
    ));
  }
  return (
    <>
      {errors ? (
        <Trans i18nKey={messageCode}>{errors}</Trans>
      ) : (
        <Trans i18nKey={messageCode}>
          There was a problem with your submission. Please try again.
        </Trans>
      )}
    </>
  );
};
const FormResponse = ({
  formikProps = {},
  errors,
  isVisible,
  children,
  messageCode,
  alert,
  onClose,
}) => {
  const isOpen =
    ((formikProps.status || {}).isSubmitted && !formikProps.isSubmitting) ||
    isVisible ||
    false;
  //    const isVisible = (formikProps.status || {}).isSubmitted || formikProps.isSubmitting || false;
  const errorsFormatted = getErrors(errors, messageCode);

  const color = errorsFormatted ? "danger" : "success";
  const message = errorsFormatted ? errorsFormatted : children;
  //   const getFeedbackIcon = () => {
  //     if (errorsFormatted) {
  //       return <img src={alertError} />;
  //     } else if (alert) {
  //       return <img src={alertInfo} className={`mr-1 ${styles.alertTriangle}`} />;
  //     }

  //     return <img src={alertSuccess} />;
  //   };
  const getAlertColor = () => {
    if (errorsFormatted) {
      return "danger";
    } else if (alert) {
      return "warning";
    }
    return "success";
  };

  const getFeedbackMessaging = () => {
    if (errorsFormatted) {
      return (
        <Trans i18nKey={messageCode} /*className={`${styles.feedbackText}`}*/>
          {errorsFormatted}
        </Trans>
      );
    } else if (alert) {
      return (
        <Trans /*className={`${styles.feedbackText}`}*/>
          Please confirm the fields and submit again.
        </Trans>
      );
    }
    return (
      <span /* className={`${styles.feedbackText}`}*/>
        {/* <b className="mr-1">Success:</b> */}
        {children === true ? "Your info was submitted." : children}
      </span>
    );
  };
  // const getMessage = () => {
  //   if (errorsFormatted) {
  //     return "Failed:";
  //   } else if (alert) {
  //     return "Alert:";
  //   }
  //   return "Success:";
  // };
  return (
    isOpen && (
      <div
        className={`w-100 alert alert-${getAlertColor()} d-flex alert-dismissible d-flex align-items-center`}
        role="alert"
      >
        <span className="mr-1">
          {/* {getFeedbackIcon()} */}
          {/* {errorsFormatted ? <XCircle /> : <CheckCircle />} */}
        </span>
        {/* <span> */}
        {/* <b className="mr-1">{errorsFormatted ? 'Failed:' : 'Success:'}</b> */}
        {getFeedbackMessaging()}
        {/* <button
          onClick={() => onClose()}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> */}
        {/* </span> */}
      </div>
    )
  );
};

FormResponse.defaultProps = {
  onClose: () => {},
  warningMessage: "Please confirm the fields and submit again.",
};

export default FormResponse;
